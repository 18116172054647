<template>
  <div class="work flex">
    <div class="work_left">
      <ul class="tab flex marB20">
        <li
          v-for="(item, index) in tab"
          :key="index"
          :class="{ active: active == index }"
          @click="tabs(index)"
        >
          {{ item }}
        </li>
        <li :class="{ active: active == 5 }" @click="tabs('5','tui')">
          机构推荐
        </li>
      </ul>
      <!-- 投递成功 1 -->
      <ul
        v-show="active == '0'"
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <li class="box flex" v-for="item in workList" :key="item.id">
          <div class="left" v-if="item.jobObject">
            <router-link
              :to="{
                path: '/job/jobDetail/',
                query: { id: item.jobObject.id },
              }"
              :title="item.jobName"
              target="_blank"
            >
              <h2>{{ item.jobObject.jobName }}</h2>
            </router-link>
            <p class="money" v-if="item.jobObject.jobSalary">
              {{ item.jobObject.jobSalary }}
            </p>
            <p class="mon_ey" v-else>面议</p>
            <div class="company">{{ item.companyObject.companyName }}</div>
          </div>
          <div class="right">
            <div class="type">
              <span>投递成功</span>
            </div>
            <div class="time">{{ item.createTime }}</div>
          </div>
        </li>
      </ul>
      <!-- 被查看 2 -->
      <ul
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-show="active == '1'"
      >
        <li class="box flex" v-for="(item, index) in workList" :key="index">
          <div class="left" v-if="item.jobObject">
            <router-link
              :to="{
                path: '/job/jobDetail/',
                query: { id: item.jobObject.id },
              }"
              :title="item.jobName"
              target="_blank"
            >
              <h2>{{ item.jobObject.jobName }}</h2>
            </router-link>
            <p class="money" v-if="item.jobObject.jobSalary">
              {{ item.jobObject.jobSalary }}
            </p>
            <p class="mon_ey" v-else>面议</p>
            <div class="company">{{ item.companyObject.companyName }}</div>
          </div>
          <div class="right">
            <div class="type">
              <span>被查看</span>
            </div>
            <div class="time">{{ item.createTime }}</div>
          </div>
        </li>
      </ul>
      <!-- 有意向 3 -->
      <ul
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-show="active == '2'"
      >
        <li class="box flex" v-for="item in workList" :key="item.id">
          <div class="left" v-if="item.jobObject">
            <router-link
              :to="{
                path: '/job/jobDetail/',
                query: { id: item.jobObject.id },
              }"
              :title="item.jobName"
              target="_blank"
            >
              <h2>{{ item.jobObject.jobName }}</h2>
            </router-link>
            <p class="money" v-if="item.jobObject.jobSalary">
              {{ item.jobObject.jobSalary }}
            </p>
            <p class="mon_ey" v-else>面议</p>
            <div class="company">{{ item.companyObject.companyName }}</div>
          </div>
          <div class="right">
            <div class="type">
              <span>有意向</span>
            </div>
            <div class="time">{{ item.createTime }}</div>
          </div>
        </li>
      </ul>
      <!-- 邀请面试 4 5 6-->
      <ul
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-show="active == '3'"
      >
        <li class="box flex" v-for="(item, index) in workList" :key="item.id">
          <div class="left" v-if="item.jobObject">
            <router-link
              :to="{
                path: '/job/jobDetail/',
                query: { id: item.jobObject.id },
              }"
              :title="item.jobName"
              target="_blank"
            >
              <h2>{{ item.jobObject.jobName }}</h2>
            </router-link>
            <p class="money" v-if="item.jobObject.jobSalary">
              {{ item.jobObject.jobSalary }}
            </p>
            <p class="mon_ey" v-else>面议</p>
            <div class="company" v-if="item.companyObject.companyName">
              {{ item.companyObject.companyName }}
            </div>
            <div class="company" v-else>无</div>
          </div>
          <div class="right relative">
            <div class="type text_c">
              <el-button
                type="primary"
                size="mini"
                @click="handleClick(index, item.applyStatus)"
                v-if="
                  item.applyStatus == '4' ||
                    item.applyStatus == '5' ||
                    item.applyStatus == '6'
                "
                class="block marB5"
                >查看详情
              </el-button>
              <span v-if="item.applyStatus == '5'">已接受</span>
              <span v-if="item.applyStatus == '6'" class="styl">已拒绝</span>
            </div>
            <div class="time">{{ item.interviewInfo.interviewTime }}</div>
          </div>
        </li>
      </ul>
      <!-- 机构推荐 -->
      <ul class="list loads" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
        <li class="box flex" v-for="(item, index) in institutionList" :key="item.id">
          <div class="left" v-if="item">
            <router-link :to="{
                path: '/job/jobDetail/',
                query: { id: item.id },
              }" :title="item.jobName" target="_blank">
              <h2>{{ item.jobName }}</h2>
            </router-link>
            <p class="money" v-if="item.salary">
              {{ item.salaryy }}
            </p>
            <p class="mon_ey" v-else>面议</p>
            <div class="company" v-if="item.deptName">
              {{ item.deptName}}
            </div>
            <div class="company" v-else>无</div>
          </div>
          <div class="right relative" @click="$router.push('/job/jobDetail?id='+item.jobId)">
            <div class="type text_c">
              <el-button type="primary" size="mini" class="block marB5">投递简历</el-button>
            </div>
            <div class="time">{{ $dateFormat(item.createTime, 'YYYY-MM-DD HH:mm')}}</div>
          </div>
        </li>
      </ul>
      <el-dialog
        :title="title"
        :visible.sync="isShow"
        width="500px"
        :before-close="handleClose"
        v-if="isShow"
      >
        <el-form ref="form" class="form">
          <el-form-item label="联系人：" prop="linkMan">{{
            interviewInfo.linkMan
          }}</el-form-item>
          <el-form-item label="联系电话：" prop="linkTel">{{
            interviewInfo.linkTel
          }}</el-form-item>
          <el-form-item label="面试时间：" prop="interviewTime"
            >{{ interviewInfo.interviewTime }}
          </el-form-item>
          <el-form-item label="面试方式：" v-if="interviewInfo.isSp==1">
            远程面试（线下通过微信或者其他途径面试）
          </el-form-item>
          <el-form-item label="面试地点：" prop="provinceid">
            {{ interviewInfo.provinceid }}{{ interviewInfo.cityid
            }}{{ interviewInfo.threeCityid }}
          </el-form-item>
          <el-form-item label="详细地址：" prop="address">{{
            interviewInfo.address
          }}</el-form-item>
          <el-form-item label="面试备注：" class="text_L">
            <p v-html="interviewInfo.applyMessage"></p>
          </el-form-item>
          <div class="buttons">
            <el-button
              v-if="typeDetail == '4'"
              type="primary"
              class="marR20"
              @click="agrees(interviewInfo.id, 5)"
              >接受
            </el-button>
            <el-button
              type="info"
              style="margin-left:0px"
              @click="resufes(interviewInfo.id, 6)"
              v-if="typeDetail == '4'"
              >拒绝
            </el-button>
          </div>
        </el-form>
      </el-dialog>
      <!-- 不合适 7-->
      <div v-if="status.isHide == 0">
        <ul
          class="list loads"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          v-show="active == '4'"
        >
          <li class="box flex" v-for="item in workList" :key="item.id">
            <div class="left" v-if="item.jobObject">
              <router-link
                :to="{
                  path: '/job/jobDetail/',
                  query: { id: item.jobObject.id },
                }"
                :title="item.jobName"
                target="_blank"
              >
                <h2>{{ item.jobObject.jobName }}</h2>
              </router-link>
              <p class="money" v-if="item.jobObject.jobSalary">
                {{ item.jobObject.jobSalary }}
              </p>
              <p class="mon_ey" v-else>面议</p>
              <div class="company">{{ item.companyObject.companyName }}</div>
            </div>
            <div class="right">
              <div class="type">
                <span>不合适</span>
              </div>
              <div class="time">{{ item.createTime }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div v-else>
        <status :type="status.type" :des="status.des"></status>
      </div>

      <!-- 分页 -->
      <div class="pages">
        <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="work.pageNum"
          :page-size="work.pageSize"
          layout="prev, pager, next, total, jumper"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- 右边 优选推荐 -->
    <div class="work_right">
      <div class="top flex cursorP">
        <viewer :images="image" class="inline_block viewer">
          <img src="~/static/public/apps.png" alt="" />
          <!-- <qr-code></qr-code> -->
        </viewer>
        <div class="text">点击放大QR,下载中国残联APP，安心面试，顺利求职！</div>
      </div>
      <div class="bottom">
        <div class="title">
          <h1 class="relative" v-if="location == '河南省'">
            河南省内职位推荐
            <span @click.stop="refresh">换一批</span>
          </h1>
          <h1 class="relative" v-if="location == '湖南省'">
            湖南省内职位推荐
            <span @click.stop="refresh">换一批</span>
          </h1>
          <h1 class="relative" v-if="location == ''">
            就近职位推荐
            <span @click.stop="refresh">换一批</span>
          </h1>
        </div>
        <ul class="marL10">
          <li v-for="item in recomdList" :key="item.index">
            <router-link :to="'/job/jobDetail/?id=' + item.id" target="_blank">
              <h4 class="line_clamp1">{{ item.jobName }}</h4>
            </router-link>

            <p class="mon_ey" v-if="item.jobSalary">{{ item.jobSalary }}</p>
            <p class="mon_ey" v-else>面议</p>
            <div class="company line_clamp1">
              {{ item.companyInfo.companyName }}
            </div>
            <el-divider></el-divider>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "work",

  data() {
    return {
      title: "企业邀请信息",
      active: "0",
      isShow: false,
      loading: false,
      image: [],
      tab: ["投递成功", "被查看", "有意向", "邀请面试", "不合适"],
      total: 0,
      workList: [],
      recomdList: [],
      interviewInfo: {},
      pagination: {
        pre: "上一页",
        next: "下一页",
      },
      status: {
        isHide: 0,
        type: "",
        des: "",
      },
      work: {
        pageNum: 1,
        pageSize: 10,
        applyStatus: "1",
      },
      params: {
        pageNum: 1,
        pageSize: 8,
      },
      typeDetail: "",
      location:"",
      institutionList:[],
    };
  },
  methods: {
    tabs(i) {
      this.active = i;
      this.workList = [];
      console.log("我的投递", i);
      if (this.active == "0") {
        this.work.applyStatus = 1;
      } else if (this.active == "1") {
        this.work.applyStatus = 2;
      } else if (this.active == "2") {
        this.work.applyStatus = 3;
      } else if (this.active == "3") {
        this.work.applyStatus = "4,5,6";
      } else if (this.active == '4'){
        this.work.applyStatus = 7;
      } else if (this.active == '5'){
        this.jobToDisabled();
        return
      }
      this.getWorkList();
      this.location = localStorage.getItem('locationSite');
    },
    // 请求我投递的接口
    async getWorkList() {
      try {
        this.loading = true;
        let res = await this.$api.getDisableJobApply(this.work);
        if (res.data.success) {
          this.workList = res.data.data.records;
          this.total = res.data.data.total;
          console.log( this.workList)
          this.loading = false;
          if (this.workList.length <= 0) {
            this.status.isHide = 1;
            this.status.type = "post";
            this.status.des = "暂无数据！";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.status.isHide = 1;
        this.status.type = "wifi";
      }
    },
    // 职位优选列表
    async queryAboutApplyJob() {
      // this.loading = true;
      try {
        let res;
        if(this.location == '湖南省'){
          res = await this.$api.queryAboutHopeJob(this.params);
        }else{
          res = await this.$api.queryAboutApplyJob(this.params);
        }
        console.log("优选职位", res);
        if (res.data.success) {
          this.recomdList = res.data.data.records;
          // this.loading = false;
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    jobToDisabled(){
      this.loading = true;
      this.$api.jobToDisabledApi(this.work).then(res =>{
        this.institutionList = res.data.data.records;
        this.total = res.data.data.total;
        console.log(this.institutionList)
        this.loading = false;
      })
    },
    refresh() {
      this.queryAboutApplyJob();
      this.$message.success("成功！");
    },
    handleClose() {
      this.isShow = false;
    },
    agrees(id, applyStatus) {
      let params = {
        id: id,
        applyStatus: applyStatus,
      };
      this.$confirm("若您同意当前企业对那您的邀请, 请点击确定?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$api.accexpOrjujue(params);
          if (res.data.success) {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.isShow = false;
            this.tabs(3);
          } else {
            this.$message.error(res.data.msg);
            this.isShow = false;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.isShow = false;
        });
    },
    resufes(id, applyStatus) {
      let params = {
        id: id,
        applyStatus: applyStatus,
      };
      this.$confirm("请确定你是否拒绝当前企业对那您的邀请, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$api.accexpOrjujue(params);
          if (res.data.success) {
            this.$message({
              type: "success",
              message: "已拒绝！",
            });
            this.isShow = false;
            this.tabs(3);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.isShow = false;
        });
    },

    //分页
    handleCurrentChange(val) {
      this.work.pageNum = val;
      this.tabs(this.active);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.tabs(this.active);
    },
    handleClick(index, type) {
      this.interviewInfo = this.workList[index].interviewInfo;
      this.interviewInfo.id = this.workList[index].id;
      this.isShow = true;
      this.typeDetail = type;
    },
  },
  mounted() {
    let active = this.$route.query.active || 0;
    this.tabs(active);
    this.queryAboutApplyJob();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/center/center.less";

.mon_ey {
  color: #f65150;
  font-size: 14px;
  font-weight: bold;
  padding-right: 10px;
  margin: 6px 0;
}

.buttons {
  display: flex;
  width: 60%;
  margin: auto;
  justify-content: space-around;
}

.styl {
  color: red;
}

/deep/ .el-loading-spinner {
  top: 50%;
}

/deep/ .el-divider--horizontal {
  margin: 10px 0;
}

/deep/ .form {
  padding: 0 40px;

  .el-form-item {
    border-bottom: 1px solid #e1e1e1;
  }

  .buttons {
    button {
      width: 220px;
      margin-right: 20px;
    }
  }
}
.block {
  position: absolute;
  top: 20px;
  right: 0;
}
</style>
